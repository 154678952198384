export default [
  {
    title: 'Thống kê',
    route: 'home',
    icon: 'PieChartIcon',
  },
  {
    title: 'Lịch sử giao dịch',
    route: 'transaction_history',
    icon: 'ListIcon',
  },
  {
    title: 'Rút tiền',
    route: 'withdrawal_banks_list',
    icon: 'SendIcon',
  },
  {
    header: 'Notification',
  },
  {
    title: 'Donate',
    route: 'widget-donate',
    icon: 'RadioIcon',
  },
  {
    title: 'Mục Tiêu',
    route: 'widget-process-bar',
    icon: 'RadioIcon',
  },
  {
    title: 'Top 5',
    route: 'widget-top-lists',
    icon: 'RadioIcon',
  },
]
