<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <span class="d-none d-sm-inline-block">, Một dịch vụ của EPIS VietNam</span>
    </span>

    <span class="float-md-right d-none d-md-block"><a href="https://gwu.statuspage.io/" target="_blank">Status</a>
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
export default {
}
</script>
